.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.custom-upload {
  border: 2px dashed #ccc;
  padding: 20px;
  text-align: center;
  cursor: pointer;
}

.box-header{
  padding: 2px;
  color: #fff;
  background-color: darkslategrey;
}

.zoomable-image-container {
  position: relative;
  width: 100%;
  height: 100%;
}

.zoomable-image {
  /*position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;*/
  display: flex;
  justify-content: center;
  align-items: center;
}

.zoomable-image:hover {
  /*transform: scale(1.5);  Zoom effect */
}

.absolutely-positioned {
  position: absolute;
  top: 50px; /* Position en haut en pixels */
  left: 50%; /* Déplace le texte vers la moitié de la largeur du conteneur */
  transform: translateX(-50%); /* Centre le texte horizontalement */
  color: #282c34;
  z-index: 1;
  background-color: aliceblue;
  padding: 2px;
  border-radius: 5px;
}

.PhotoView-Slider__Backdrop{
  background-image: url("../public/media/images/damier_front.jpg")!important;
  background-size: cover;
}

.bg-dark{
  --bs-bg-opacity: 1;
  background-color: rgba(33, 37, 41, 1) !important;
}

.text-white{
  color: #ffffff;
}

.text-title{
  color: #ffffff;
  font-weight: bold;
  text-align: center;
}

.text-warning{
  color: #cc9107;
}

.round-3{
  border-radius: 3px;
}

.round-4{
  border-radius: 4px;
}

.img-c {
  position: relative;
  overflow: hidden;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.img-c img {
  max-width: 100%;
  height: auto;
  display: block;
  transition: transform 0.3s ease;
}

.img-c:hover img {
  transform: scale(1.05);
}

.file-list {
  list-style-type: none;
  padding: 0;
}

.file-item {
  background-color: #f0f0f0;
  border: 1px solid #ddd;
  margin-bottom: 5px;
  padding: 10px;
}

.file-name {
  font-weight: bold;
}

.file-size {
  color: #888;
}

.sticky-select {
  background-color: #FFF;
  padding: 10px;
  position: -webkit-sticky; /* Pour la compatibilité avec Safari/IOS */
  position: sticky;
  top: 0; /* Fixe en haut de la fenêtre */
  z-index: 1000; /* Définissez un z-index pour gérer la superposition */
}

.del-top-right {
  position: absolute;
  top: 0;
  right: 0;
  padding: 5px 10px; /* Espacement autour du texte */
  font-size: 14px;
  color: black;
}
